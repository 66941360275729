import { Column } from './Column'
import { TrustedSiteMark } from 'components/content/trust/TrustedSite'
import { TrustpilotWidget } from 'components/vendor/TrustpilotWidget'
import { Icon, Link, Text, View } from 'reshaped/bundle'
import { TwitterIcon } from './assets/TwitterIcon'
import { FacebookIcon } from './assets/FacebookIcon'

export const PrimaryLinks = () => {
	return (
		<View gap={8} direction={{ m: 'row' }}>
			<View.Item columns={{ s: 12, m: 3 }}>
				<Column
					title={'Who We Are'}
					links={[
						{ text: 'Home', href: '/' },
						{ text: 'About Us', href: '/about' },
						{ text: 'Trustpilot Reviews', href: '/about/reviews' }
					]}
				/>
			</View.Item>
			<View.Item columns={{ s: 12, m: 3 }}>
				<Column
					title={'Financial Services'}
					links={[
						{ text: 'Personal Loans', href: '/products/loans' },
						{ text: 'Student Loan Refinance', href: '/products/student-loan-refinance' },
						{ text: 'Mortgages', href: '/explore/mortgage' },
						{ text: 'Credit Cards', href: '/explore/credit-cards' },
						{ text: 'Savings', href: '/explore/savings' },
						{
							text: 'Life Insurance',
							href: 'https://www.leaplife.com/partner/fionalifeinsurance/life-insurance?tag.src=fiona&utm_source=fiona'
						},
						{ text: 'Auto Insurance', href: '/explore/auto-insurance/redirect', external: true },
						{
							text: 'Credit Score',
							href: 'https://offers.evenfinancial.com/partner/ref/516a1f88-3d04-4581-b366-0f5d939ee72d/9a87cee4-8df5-4c6f-952d-82743552a4e4',
							external: true
						}
					]}
				/>
			</View.Item>
			<View.Item columns={{ s: 12, m: 3 }}>
				<Column
					title={'Learn'}
					links={[
						{ text: 'Overview', href: '/learn' },
						{ text: 'About Loans', href: '/learn/products/loans' },
						{ text: 'About Mortgage', href: '/learn/products/mortgage' },
						{ text: 'About Insurance', href: '/learn/products/insurance' },
						{ text: 'About Credit Cards', href: '/learn/products/credit-cards' },
						{ text: 'About Savings', href: '/learn/products/savings' },
						{ text: 'Our Network', href: '/learn/partners' },
						{ text: 'All Topics', href: '/learn/topics' },
						{ text: 'Glossary', href: '/learn/glossary' }
					]}
				/>
			</View.Item>
			<View.Item columns={{ s: 12, m: 3 }}>
				<View gap={8}>
					<Column
						title={'Support'}
						links={[
							{ text: 'FAQ & Support', href: '/support' },
							{ text: 'Contact Us', href: '/contact' }
						]}
					/>
					<View gap={6}>
						<Text variant="body-3" weight="bold" attributes={{ style: { textTransform: 'uppercase' } }}>
							Connect
						</Text>
						<View direction="row" gap={4} align="center">
							<Link
								color="inherit"
								href="https://www.facebook.com/hifionafinance"
								attributes={{ target: '_blank', rel: 'noopener noreferrer' }}
							>
								<Icon svg={FacebookIcon} size={6} />
							</Link>
							<Link
								color="inherit"
								href="https://twitter.com/hifionafinance"
								attributes={{ target: '_blank', rel: 'noopener noreferrer' }}
							>
								<Icon svg={TwitterIcon} size={6} />
							</Link>
							<Link
								href="https://www.trustedsite.com/verify?host=fiona.com"
								attributes={{ target: '_blank', rel: 'noopener noreferrer' }}
							>
								<TrustedSiteMark />
							</Link>
						</View>
						<View width={20}>
							<TrustpilotWidget theme="dark" widget="mini" />
						</View>
					</View>
				</View>
			</View.Item>
		</View>
	)
}
